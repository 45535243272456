// HEAD;
// import logo from "./logo.svg";
// import "./App.css";
// import Home from "./pages/Home/Home";
// import Consulting from "./pages/Consulting/Consulting";
// import WhyKasookoo from "./pages/WhyKasookoo/WhyKasookoo";
// import Navbar from "./components/navbar/Navbar";
// import Footer from "./components/footer/Footer";

import logo from "./logo.svg";
import "./App.css";
import './style.css'

import Home from "./pages/Home/Home";
import ContactCenter from "./pages/ContactCenter/ContactCenter";
import AiSolutions from "./pages/AiSolutions/AiSolutions";
import AfricaTechFestival2023 from "./pages/AfricaTechFestival2023/AfricaTechFestival2023";
import CloudNumberingService from "./pages/CloudNumberService/CloudNumberService";
import Consulting from "./pages/Consulting/Consulting";
import WhyKasookoo from "./pages/WhyKasookoo/WhyKasookoo";
import CompaignManager from "./pages/CompaignManager/CompaignManager";
import InAppVoiceCalling from "./pages/InAppVoiceCalling/InAppVoiceCalling";
import KasookooForCRM from "./pages/KasookooForCRM/KasookooForCRM";
import KasookooForZoho from "./pages/KasookooForZoho/KasookooForZoho";
import KasookooForMicrosoft from "./pages/KasookooForMicrosoft/KasookooForMicrosoft";
import ProgrammableMessaging from "./pages/ProgrammableMessaging/ProgrammableMessaging";
import KasookooForZoom from "./pages/KasookooForZoom/KasookooForZoom";
import GovernmentSolution from "./pages/GovernmentSolution/GovernmentSolution";
import Hostpitality from "./pages/Hospitality/Hostpitality";
import AboutUS from "./pages/AboutUS/AboutUs"
import FinTech from "./pages/FinTech/FinTech";
import SharingEconomy from './pages/SharingEconomy/SharingEconomy'
import InsuranceServices from './pages/InsuranceServices/InsuranceServices'
import NumberMasking from "./pages/NumberMasking/NumberMasking";
import ProgrammableVoice from "./pages/ProgrammableVoice/ProgrammableVoice";
import RetaileCommerce from './pages/Retail&eCommerce/RetailAndeCommerce'
import UserVarification from './pages/UserVarification/UserVarification'
import Careers from "./pages/Careers/Careers";
import ViewJob from "./pages/ViewJob/ViewJob";
import Partners from "./pages/Partners/Partners";
import Press from "./pages/Press/Press";
import HelpCenter from './pages/Support Page Helpcenter/HelpCenter'
import Resources from './pages/ResourcesPage/Resources'
import Carrier from "./pages/Carrier/Carrier";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faqs from './pages/Faqs/Faqs';
import GDPR from "./pages/GDPR/GDPR";
import TermAndConditions from "./pages/TermsAndConditions/TermAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import EduTech from "./pages/EDUTech/EduTech";
import DebtCollection from "./pages/DebtCollection/DebtCollection";

import KasookooStories from "./pages/KasookoStories/KasookooStories";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

import DufilFullStory from "./pages/DufilFullStory/DufilFullStory";
import WellaHealthStory from "./pages/WellAHealth/WellaHealthStory";
import TradespotFullStory from "./pages/TradespotFullStory/TradespotFullStory";
import RichFieldFullStory from "./pages/RichFieldStory/RichFieldFullStory";



// import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import "./kasookoo.css";

import { useState } from "react";

function App() {


  const [isFormOpen,setIsFormOpen] = useState(false)
  return (
    <>
      
    
      <Router>
        <Routes>
          <Route path="/" element={<Home setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/contact-center" element={<ContactCenter setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/ai-solutions" element={<AiSolutions setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/africa-tech-festival-2023" element={<AfricaTechFestival2023 setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/cloud-numbering-service" element={<CloudNumberingService setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/EduTech" element={<EduTech setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route>
          <Route path="/debtcollection" element={<DebtCollection setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route>
          <Route path="/dufil-fullstory" element={<DufilFullStory setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/wellahealth-fullstory" element={<WellaHealthStory setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/tradespot-fullstory" element={<TradespotFullStory setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route>
          <Route path="/richfield-fullstory" element={<RichFieldFullStory setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/about-us" element={<AboutUS setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route> 
          <Route path="/campaign-manager" element={<CompaignManager setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/in-app-voice-calling" element={<InAppVoiceCalling setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/kasookoo-for-crm" element={<KasookooForCRM setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/zoho-phonebridge-integration" element={<KasookooForZoho setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          {/* <Route path="/kasookoo-for-microsoft" element={<KasookooForMicrosoft setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route> */}
          <Route path="/programmable-messaging" element={<ProgrammableMessaging setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/kasookoo-for-zoom" element={<KasookooForZoom setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />}></Route>
          <Route path="/number-masking" element={<NumberMasking setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/government-solution" element={<GovernmentSolution setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/hostpitality" element={<Hostpitality setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          {/* <Route path="/insurance-services" element={<InsuranceServices setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route> */}
          <Route path="/consutling" element={<Consulting setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/why-kasookoo" element={<WhyKasookoo setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/programmable-voice" element={<ProgrammableVoice setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/FinTech" element={<FinTech setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/outbound-callcentre" element={<SharingEconomy setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/retail-ecommerce" element={<RetaileCommerce setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/user-varification" element={<UserVarification setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/customer" element={<Resources setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/careers" element={<Careers setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/careers/:title" element={<ViewJob setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/userstories" element={<KasookooStories setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>

          {/* <Route path="/partners" element={<Partners setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route> */}
          <Route path="/press" element={<Press setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          {/* <Route path="/carrier-services" element={<Carrier />}></Route> */}
          <Route path="/contact-us" element={<ContactUs setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/help-center" element={<HelpCenter setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/faqs" element={<Faqs setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/gdpr" element={<GDPR setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/terms-and-conditions" element={<TermAndConditions setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen}/>}></Route>

          {/* 
          
          <Route path="/Components" element={<ComponentsPage />}></Route>
          
          
          
          
          
          
          
          
          
          
           */}

        </Routes>
      </Router>
      {/* <Navbar></Navbar> */}
      {/* <Home></Home> */}
      {/* <Footer></Footer> */}
    </>
  );
}

export default App;
