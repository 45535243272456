import React,{useState} from "react";
import "./aisolutions.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize_customer_img.png";
import bottomleftimg from "../../assets/images/7-2.png"
import bottomrightimg from "../../assets/images/7-1.png"

import designedforyou from "../../assets/images/designedforyourbusiness.svg";
import scalingsolution from "../../assets/images/scalingsolution.svg";
import securitypriority from "../../assets/images/securitypriority.svg";
import dedicatedsupport from "../../assets/images/dedicatedsupport.svg";

import contactcenterhelpdesk from "../../assets/images/contact-center-help-desk.svg"
import targeticon from "../../assets/images/target.svg"
import worldwideicon from "../../assets/images/worldwide-contact-center-icon.svg"
import interactionicon from "../../assets/images/contact-center-interaction-icon.svg"
import Slider from "../../components/Slider/Slider";
import admin_view_icon from '../../assets/images/admin_view_icon.svg'
import crm_integration_icon from '../../assets/images/crm_integration_icon.svg'
import managing_contact_img from '../../assets/images/managing_a_contact.png'

import equip_your_img from '../../assets/images/equip_your_img.png'
import Feedback from "../../components/Feedback/Feedback"
import embedded_voice_calling_img from '../../assets/images/embedded_voice_calling_img.png'

import access_all_your_customer from '../../assets/images/access_all_your_customer.png'
import organizing_campaigns_img from '../../assets/images/organizing_campaigns_img.png'
import Intergrate_email_img from '../../assets/images/Intergrate_email_img.png'
import leverage_whatsapp_img from '../../assets/images/leverage_whatsapp_img.png'
import seamless_voice_calls_img from '../../assets/images/seamless_voice_calls_img.png'
import ai_voice from '../../assets/images/ai_voice_img.webp'
import ai_chat from '../../assets/images/ai_chat_img.png'

import dufil from "../../assets/clientlogo/13.png"
import tradedepot from "../../assets/clientlogo/12.png"
import wellahealth from "../../assets/clientlogo/wellahealth.png"
import via_SMS from '../../assets/images/via_SMS.png'

export default function AiSolutions(props) {
  const feedback =[
    {
        quote:
            'Kasookoo’s depth of industry knowledge, world-class infrastructure, top-notch support and presence in multiple African countries was what we needed to support our expansion plans. They have helped us stay connected to our customers, resolve issues, have real-time conversations with them about matters that are pertinent to them. Most importantly, we built trust at Trade Depot and transition our Telesales team to a remote distributed team.',
        name: 'Feyishayo Akimoboye',
        role: 'Lead, Telecomms Team, Trade Depot',
        image: tradedepot,
    },
    {
        quote: 'Kasookoo’s has everything it take to deliver on a messaging campaign and that is why we trusted them for our campaign.',
        name: 'Dufil Foods',
        role: '',
        image: dufil,
    },
    {
        quote:
            'Kasookoo helped us to optimize our operations and team strength in responding and communication with our clients. The insights from the Kasookoo platform were especially relevant to help us make strategic marketing and customer service decisions.',
        name: 'Wella Health',
        role: '',
        image: wellahealth,
    },
];
  return (
    <div className="ai-soulutions">
      <Navbar
        extra_heading = "Kasookoo AI Solutions"
        heading="AI-powered Customer Experiences"
        text="Empower your agents and managers to drive seamless, personalised and efficient interactions across all touchpoints to improve customer experience (CX) with AI solutions tailored to Africa."
        img={optimize_customer_img}
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
        contact_center_form ="true"
      ></Navbar>
      
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title align-items-center text-center pb-2"
      >
        <style></style>

        <div class="w-100 section-centered_title align-items-center text-center align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Unleash CX Potential Across Africa with AI
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pt-2"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={designedforyou}
                      alt="Group 1000005103"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Empower your agents</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                    Provide your agents with deep insights to understand and anticipate your customer needs. Help them to offer personalised, informed and empathetic support.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={scalingsolution}
                      alt="Group 1000005101"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                    Custom-tuned for African languages
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                    Enhance accuracy for regional languages and accents, and adapt models to your business needs. Transform customer interactions into engaging, meaningful experiences.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={dedicatedsupport}
                      alt="Group 1000004943"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Elevating productivity</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                    Leverage AI within your contact centre to allow your agents and managers to summarise, analyse and focus on what truly matters – building stronger, more meaningful relationships with your customers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title text-start text-md-center pb-3 purple-background"
      >
        <style></style>

        <div class=" w-100 section-centered_title text-start text-md-center pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h3 class="style-h3">
                  Empowering your team with AI-powered solutions
                </h3>
              </div>
              <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                  Unlock the power of automation to harness the local nuances of the African region, boosting productivity, operational efficiency, and elevating customer experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="num-section-12" class="w-100 position-relative block-accordion_list py-5 purple-background">

        <AccordionComponent/>
         
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title text-start text-md-center pb-3"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h3 class="style-h3">
                Enhance CX at Scale with Kasookoo AI Agents
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-items_left_icon pb-5"
      >
        <style></style>
        <div class="w-100 section-items_left_icon">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={targeticon}
                    alt="Efficiency at Scale"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <h5 class="mb-0 mt-3">Efficiency at Scale</h5>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={worldwideicon}
                    alt="Boost Customer Satisfaction"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <h5 class="mb-0 mt-1">
                    Boost Customer Satisfaction
                  </h5>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={interactionicon}
                    alt="Multilingual Mastery"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <h5 class="mb-0 mt-3">
                    Multilingual Mastery
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-13"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-6 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    Coming soon
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Supercharge your Contact Centre with AI Voice Agents
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                    Free up your live agents to tackle more complex issues by implementing Kasookoo AI Voice Agents. Our reliable AI can consistently handle at least 30% of your calls, ensuring a great experience for your customers while significantly reducing wait times.
                    </p>
                    <p>
                      {/* <a href="#">Discover kosooko for Salesforce.</a> */}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 side-two  ps-md-5">
                <img
                  src={ai_voice}
                  alt="Image 42"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-13"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
            <div class="col-12 col-md-6 col-lg-6 side-two  ps-md-5">
                <img
                  src={ai_chat}
                  alt="Image 42"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-6 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    Coming soon
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                  Enhance Customer Engagement 24/7 with AI Chat Agents
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                    Handle routine inquiries efficiently and scale faster with Kasookoo’s Coversational AI platform. Design effective experiences that enable AI chat agents and live agents to work together, providing a superior customer experience. Meet your customers on their preferred messaging channels, offering flexibility  and 24/7 support.
                    </p>
                    <p>
                      {/* <a href="#">Discover kosooko for Salesforce.</a> */}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        class="w-100 position-relative block-cta_strip_2_images green-background"
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomleftimg}
                  alt="Ready for a CCaaS that scales with your growth plans?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomrightimg}
                  alt="Ready for a CCaaS that scales with your growth plans?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Looking for a CCaaS solution that can grow with your
                    business aspirations?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <button
                    style={{border:'none'}}
                    class="blact_btn mb-5 mb-md-0 mx-auto"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Connect With Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}



export function AccordionComponent() {
 

  const list_accordion_6 =  [
    {
      title: "Voice Generator",
      text: "<p>Revolutionise your customer interactions with Kasookoo Voice Generator, the advanced text-to-speech technology that transcends traditional IVR limits. Clone and set your preferred voice across your contact centre effortlessly, transforming your IVR without studio recordings. Embrace a future where every customer connection is engaging, personal, and seamlessly efficient. <br/><br/>Forget the constraints of coordinating with voice talents. Our technology ensures your voice services are always available, adaptable, and perfectly aligned with your company’s evolving needs. Enhance your scalability and cost-efficiency effortlessly.</p>",
      image:seamless_voice_calls_img,
      bottom_arrow_link: {
        title: "Voice Generator",
        url: "",
        target: "",
      },
    },
  
    {
      title: "Transcribe",
      text: "<p>Elevate customer satisfaction by auto-transcribing conversations across languages and accents into your CRM and enabling easy tagging for sentiment analysis. <br/><br/>Simplify training for new agents, guarantee support quality and enable quick comprehension of call conversations without the need to listen to full recordings, streamlining your team’s efficiency.</p>",
      image: leverage_whatsapp_img,
      bottom_arrow_link: {
        title: "Transcribe",
        url: "/contact-us",
        target: "",
      },
    },


    {
      title: "Summarise",
      text: "<p>Effortlessly capture every interaction detail, enabling your team to focus more on the conversation instead of note-taking. Streamline call wrap-ups to significantly improve efficiency.<br/><br/> Boost your team’s productivity by 8%* with Kasookoo’s accurate summarisation solution, designed to increase the average number of calls your agents can handle efficiently and effectively.<br/><br/><small> *Based on a typical scenario where an agent spends an average of 90 seconds on a wrap-up, they can reduce that time by 30 seconds with Kasookoo AI, assuming an agent handles an average of 60 calls per day.</small></p>",
      image:via_SMS,
      bottom_arrow_link: {
        title: "Summarise",
        url: "",
        target: "",
      },
    },
    {
      title: "Conversation Analytics",
      text: "<p>alk to your data like never before. Empower your supervisors and managers to engage directly with data in unprecedented ways. With Kasookoo’s AI-powered Conversation Analytics, ask questions in natural language and receive comprehensive reports instantly, streamlining your decision-making process. <br/><br/>Eliminate the need for complex SQL queries and lengthy coordination with Business Intelligence teams. Your quality assurance and compliance teams can now swiftly identify and review key customer interactions, focussing on what truly matters.<br/><br/>Harness advanced text classification and clustering to act on the most impactful insights. Plus, leverage these insights to create self-service options for less critical issues, enhancing efficiency.</p>",
      image:Intergrate_email_img,
      bottom_arrow_link: {
        title: "Integrate email with your contact center",
        url: "/contact-us/",
        target: "",
      },
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
    
      <div className="w-100 section-accordion_list ng-scope">
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${
                        index === activeItem ? "style-h4" : "collapsed style-h4"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              {/* <h3 className="style-h3 mb-5">Diverse Channels, Countless Advantages</h3> */}
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                    index === activeItem ? "active" : "text-dark"
                  }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              {/* <a
                className="btn btn-arrow text-start ng-binding ng-hide"
                href={list_accordion_6[activeItem].bottom_arrow_link.url}
              >{list_accordion_6[activeItem].bottom_arrow_link.title}</a> */}
            </div>
          </div>
        </div>
      </div>

  );
}