import React, { useState, useEffect } from "react";
import "./contactus.css";
import Footer from "../../components/footer/Footer";
import customer_support from "../../assets/images/customer-support.jpeg";

import Navbar from "../../components/navbar/Navbar";
import emailjs from "@emailjs/browser";
import close_icon from "./../../assets/images/close.svg";
import africa_contact_us_1 from "./../../assets/images/Contact Us Image.png";
import africa_contact_us_2 from "./../../assets/images/africa_contact_us_2.png";
import Swal from 'sweetalert2';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

export default function ContactUs(props) {
  return (
    <div className="contact-us">
      <Navbar
        heading="Optimize customer engagement on a large scale."
        text="With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era."
        img={customer_support}
        first_section={false}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>
      <div
        id="hero"
        class="container-fluid text-white position-relative num-hero-1 hero-contact_us "
      >
        <div class="container pb-md-5 ">
          <style></style>
          <div class="row align-items-start">
            <div class="col-12 col-lg-6">
              <div class="w-100">
                <div class="d-block mb-4 text-center text-lg-start">
                  <h2 class="style-h1">Contact Us</h2>
                </div>
                <div class="d-block mb-5 pb-lg-3 text-center text-lg-start">
                  <div class="p-big">
                    <p>
                      Got a question about Kasookoo's solutions and services?
                      Fill in your details, and we’ll be in touch!
                    </p>
                  </div>
                </div>
                <div class="d-none d-lg-block pb-3">
                  <h2 class="title-section-h2">Our Office</h2>
                </div>
                <div class="d-none d-lg-block office mb-4 w-50" style={{paddingLeft:"0"}}>
                  <div class="d-block office-name pb-3">
                    <div class="style-h4">
                      <p>4th floor Silverstream House<br/>
                      45 fitzroy Street<br/>
                      Fitzrovia London<br/>
                      W1T 6EB</p>
                    </div>
                  </div>
                  <div class="d-block office-address">
                    <p>
                      <h3>Telephone</h3>
                      <h4>
                        <br />
                        +44 3333 05 40 30 <br />
                        +27 87 055 0314 <br />
                        +234 (0908) 748-2277
                      </h4>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" id="col-forms">
              {/* <script>
    var steps_fields = {"step_1":{"first_name":true,"last_name":true,"company_name":true,"phone":false,"email":true},"step_2":{"how_can_we_help":true,"like_to_know_more":false,"which_products":false,"how_many_employees":false},"step_3":{"more_details":false}};
</script> */}
              <div
                class="toku-form d-flex ng-scope"
                ng-controller="FormController"
                ng-init="total_steps = 3;"
              >
                <div
                  ng-show="submitted"
                  class="form-submission-message d-flex align-items-center ng-hide"
                >
                  <ContactForm />
                  {/* <div class="w-100">
            <h3>Thank You!</h3>
            <p>One of our experts will be reaching out to you shortly!</p>
        </div> */}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-lg-none">
              <div class="d-block pb-3">
                <h2 class="title-section-h2">Our Office</h2>
              </div>
              <div class="d-block office mb-4 " style={{paddingLeft:'0'}}>
                <div class="d-block office-name pb-3">
                  <div class="p-big">
                    <p>4th floor Silverstream House<br/>
                      45 fitzroy Street<br/>
                      Fitzrovia London<br/>
                      W1T 6EB</p>
                  </div>
                </div>
                <div class="d-block office-address">
                  <p>
                  <h3>Telephone</h3>
                  <h4>
                    <br />
                    +44 3333 05 40 30 <br />
                    +27 87 055 0314 <br />
                    +234 (0908) 748-2277
                  </h4>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-1"
        class="w-100 position-relative block-desktop_and_mobile_content "
      >
        <style></style>
        <div class="w-100 section-desktop_and_mobile_content  ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-auto d-none d-md-block ">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-3271 size-full"
                    src={africa_contact_us_1}
                    alt=""
                    width="871"
                    height="352"
                  />
                </p>
              </div>
              <div class="col-12 col-md-auto d-block d-md-none ">
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-3272 size-full"
                    src={africa_contact_us_2}
                    alt=""
                    width="328"
                    height="861"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export function ContactForm(props) {
  const [steps, setSteps] = useState([1, 2, 3]);
  const [currentStep, setCurrentStep] = useState(1);
  const [buttonText, setButtonText] = useState("Next");
  const [headingText, setHeadingText] = useState("A little bit about you...");

  useEffect(() => {
    if (props.ContactCenter === "true") {
      setHeadingText("Learn more about Kasookoo Contact Centre");
    }
  }, [props.ContactCenter]);

  const productsolutionoption = [
    "I’m looking for an omnichannel cloud-based contact centre solution",
    "I’m looking for a cloud-based call management system",
    "I’m looking for a cloud-based call management system",
    "I’d like to enable global phone calling in Microsoft Teams",
    "I’d like to learn more about Kasookoo’s connectivity for Zoom Phone",
    "I’m interested in an SMS management system",
    "I’d like to embed a calling function within my app",
    "I wish to know more about your APIs",
    "I want to get virtual numbers",
    "I’d like to know about your carrier services",
  ];
  const partnershipoptions = [
    "Becoming a Referral Partner",
    "Becoming a Technology Partner",
  ];
  const [formData, setFormData] = useState({
    firstname: "",
    secondname: "",
    companyname: "",
    phonenumber: "",
    email: "",
    howwehelp: {
      selected: "",
      subselected: "",
    },
    moredetail: "",
    noofcontactcenter: "",
    crm: "",
    agents: "",
  });
  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === "selected" || name === "subselected") {
      if (name === "selected") {
        setFormData({
          ...formData,
          howwehelp: {
            ...formData.howwehelp,
            subselected: "",
          },
        });
      }
      setFormData({
        ...formData,
        howwehelp: {
          ...formData.howwehelp,
          [name]: value,
        },
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // setFormData({
    //     ...formData,
    //     howwehelp: {
    //       [name]: value
    //     }
    //   });
  };

  const sendEmail = () => {
    emailjs
      .send(
        "service_1otg21v",
        "template_8x3knnk",
        {
          from_name: "first_name",
          from_email: "test@gmail.com",
          message: "test",
        },
        "oO37cEDSuCSUKKNtT"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleOnClick = (e) => {
    // sendEmail()
    console.log(currentStep);
    if (currentStep === 1) {
      if (formData.firstname === "") {
        console.log("test");
        alert("First Name is required");
      } else if (formData.lastname === "") {
        alert("Last Name is required");
      } else if (formData.companyname === "") {
        alert("Company Name is required");
      } else if (formData.email === "") {
        alert("Email is required");
      } else if(!formData.email.match(/^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+(?:[A-z]{2,})\b$/)){
        alert("Email is invalid");
      } else if (formData.phonenumber === "") {
        alert("Phone number is required");
      } else if(!formData.phonenumber.match(/^\+[1-9][0-9]{10,14}$/)){
        alert("Phone number is in invalid format. Use e-g: +1234567890");
      } else {
        setCurrentStep(currentStep + 1);
        if (props.ContactCenter) {
          setHeadingText(
            "Thanks for your interest in our Contact Centre solution"
          );
        } else {
          setHeadingText(
            "Please answer these quick questions so we can connect you with the right team at Kasookoo."
          );
        }
      }
    } else if (currentStep === 2) {
      if (formData.howwehelp.selected === "") {
        alert("Please fill the form");
      } else {
        if (
          (formData.howwehelp.selected === "Products and Solutions" ||
            formData.howwehelp.selected === "Partnerships") &&
          formData.howwehelp.subselected === ""
        ) {
          alert("Please fill the form");
        } else {
          setCurrentStep(currentStep + 1);
          setButtonText("Submit");
          setHeadingText("Just one last thing...");
        }
      }
    } else if (currentStep == 3) {
      const recaptchaValue = recaptchaRef.current.getValue();
      if (formData.moredetail === "") {
        alert("More Detail is required");
      }
      else if (recaptchaValue == "" || recaptchaValue.length < 1 || recaptchaValue === null){
        alert("Validate reCaptcha");
      } else {
        setCurrentStep(1);
        setButtonText("Next");
        // props.setIsFormOpen(false)
        
        const data = {
          firstName: formData.firstname,
          lastName: formData.lastname,
          email: formData.email,
          companyName: formData.companyname,
          phoneNumber: formData.phonenumber,
          textMessage: formData.moredetail,
          howWeHelp: formData.howwehelp.selected,
          helpDetail: formData.howwehelp.subselected,
          gRecaptchaResponse: recaptchaValue,
        };
        const options = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          }
        };
        axios.post('https://kasookoo.com/vmapi/signup/sendcontactformdetails', data,options)
        .then((response) => {
          console.log(response.data);
          if(response.data.success){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Thank you for providing us the details. Our representative will contact you soon',
            })
          }
          else{
            Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: response.data.message,
            })
          }
          
            // Handle data
        })
        .catch((error) => {
          console.log(error);
        });

        setFormData({
          firstname: "",
          secondname: "",
          companyname: "",
          phonenumber: "",
          email: "",
          howwehelp: {
            selected: "",
            subselected: "",
          },
          moredetail: "",
          noofcontactcenter: "",
          crm: "",
          agents: "",
        });
      }
    }
  };

  return (
    <div className="row justify-content-center align-items-center h-100 2-100">
      <div
        className="col-4 bg-white text-dark p-5 w-100"
        style={{ borderRadius: "10px" }}
      >
        {/* <div className='text-end'><button style={{border:'none',background:'transparent'}} onClick={()=>{props.setIsFormOpen(false)}}><img style={{width:'15px'}} src={close_icon} alt="" /></button></div> */}
        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col text-center">
              <p className="vsm-font m-0">
                Step {currentStep} of {steps.length}
              </p>
              <div className="d-flex justify-content-center">
                {steps.map((step_no) => {
                  return (
                    <div
                      className={
                        step_no === currentStep ? "circle current" : "circle"
                      }
                    ></div>
                  );
                })}
              </div>
              <h3 className="mb-3">{headingText}</h3>

              {currentStep === 1 && (
                <AboutYouForm
                  formData={formData}
                  handleOnChange={handleOnChange}
                />
              )}
              {currentStep === 2 &&
                (props.ContactCenter ? (
                  <ContactCenterForm
                    formData={formData}
                    handleOnChange={handleOnChange}
                    productsolutionoption={productsolutionoption}
                    partnershipoptions={partnershipoptions}
                  />
                ) : (
                  <HelpForm
                    formData={formData}
                    handleOnChange={handleOnChange}
                    productsolutionoption={productsolutionoption}
                    partnershipoptions={partnershipoptions}
                  />
                ))}
              {currentStep === 3 && (
                <MoreDetialForm
                  formData={formData}
                  handleOnChange={handleOnChange}
                />
              )}
            </div>
          </div>

          <button
            className="mt-3 contact-btn"
            onClick={handleOnClick}
            style={{ border: "none", color: "white" }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export function AboutYouForm(props) {
  return (
    <div className="contact-form1">
      <div className="row">
        <div className="col">
          {/* <div className="row justify-content-between"> */}
          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">First Name</label>
              <input
                className="w-100"
                type="text"
                name="firstname"
                value={props.formData.firstname}
                onChange={props.handleOnChange}
              />
            </div>
            </div>
            <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">Last Name</label>
              <input
                className="w-100"
                type="text"
                name="lastname"
                value={props.formData.lastname}
                onChange={props.handleOnChange}
              />
            </div>
            </div>
          {/* </div> */}

          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">Company name</label>
              <input
                className="w-100 mt-2"
                type="text"
                name="companyname"
                value={props.formData.companyname}
                onChange={props.handleOnChange}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">Phone number</label>
              <input
                className="w-100 mt-2"
                type="text"
                name="phonenumber"
                value={props.formData.phonenumber}
                onChange={props.handleOnChange}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">Email</label>
              <input
                className="w-100 mt-2"
                type="email"
                name="email"
                value={props.formData.email}
                onChange={props.handleOnChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HelpForm(props) {
  return (
    <div className="contact-form1">
      <div className="row text-start">
        <div className="col">
          <div className="row mt-1">
            <div className="col">
              <label htmlFor="">How can we help you today?*</label>
              <select
                className="mt-2 w-100"
                name="selected"
                id=""
                onChange={props.handleOnChange}
              >
                <option value="">Select the best option that fits you</option>
                <option value="Products and Solutions">
                  Products and Solutions
                </option>
                <option value="Consulting">Consulting</option>
                <option value="Partnerships">Partnerships</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          {props.formData.howwehelp.selected === "Products and Solutions" && (
            <div className="row mt-1">
              <div className="col">
                <label htmlFor="">
                  Which Kasookoo product or solutions are you interested in?
                </label>
                <select
                  className="mt-2 w-100"
                  name="subselected"
                  id=""
                  onChange={props.handleOnChange}
                >
                  <option value="">Please Select option</option>
                  {props.productsolutionoption.map((value) => {
                    return <option value={value}>{value}</option>;
                  })}
                </select>
              </div>
            </div>
          )}

          {props.formData.howwehelp.selected === "Partnerships" && (
            <div className="row mt-1">
              <div className="col">
                <label htmlFor="">I'd like to know more about</label>
                <select
                  className="mt-2 w-100"
                  name="subselected"
                  id=""
                  onChange={props.handleOnChange}
                >
                  <option value="">Please Select option</option>
                  {props.partnershipoptions.map((value) => {
                    return <option value={value}>{value}</option>;
                  })}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function MoreDetialForm(props) {
  return (
    <div className="contact-form1">
      <div className="row">
        <div className="col">
          <div className="row justify-content-between">
            <div className="col text-start">
              <label htmlFor="">
                Please provide us with more details on your enquiry here!
              </label>
              <input
                className="w-100 mb-3"
                type="text"
                name="moredetail"
                value={props.formData.moredetail}
                onChange={props.handleOnChange}
              />
              
            </div>
          </div>
          <div className="row justify-content-between">
          <ReCAPTCHA
              sitekey="6LfDkCoUAAAAAEWMeVTJCs8Tv5h7pvlviB2lOfjy"
              ref={recaptchaRef}
              // onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ContactCenterForm(props) {
  return (
    <div className="contact-form1">
      <div className="row">
        <div className="col">
          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">
                How many countries does your contact centre operate or is
                planning to operate in?*
              </label>
              <input
                className="w-100 mt-2"
                type="text"
                name="noofcontactcenter"
                value={props.formData.noofcontactcenter}
                onChange={props.handleOnChange}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">What CRM is your team currently using?*</label>
              <input
                className="w-100 mt-2"
                type="text"
                name="crm"
                value={props.formData.crm}
                onChange={props.handleOnChange}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col text-start">
              <label htmlFor="">
                How many agents are you expected to have?*
              </label>
              <input
                className="w-100 mt-2"
                type="text"
                name="agents"
                value={props.formData.agents}
                onChange={props.handleOnChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
