import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/RETAIL _ ECOMMERCE.png";

import Story from "../../components/FullStories/fullStories.js"
export default function KasookooStories() {
  return (
    //     <div>
    //       <Navbar
    //         heading="Unleash exceptional buying experiences"
    //         text="Enhance the online customer experience and streamline the customer journey by leveraging voice and messaging capabilities. Drive marketing efficiency and elevate retail customer experiences for a more seamless and satisfying interaction."
    //         img={optimize_customer_img}
    //         extra_heading="RETAIL & ECOMMERCE"
    //       ></Navbar>
    //       <main role="main">
    //         <div class="container pt-5">
    //           <div class="row pt-5 mt-md-5">
    //             <div class="col-12 col-lg-8">
    //               <div class="d-flex single-cats"></div>
    //               <div class="row single-info d-none d-lg-flex">
    //                 <div class="col-auto">January 30, 2023 </div>
    //                 <div class="space"></div>
    //                 <div class="col-auto">7 mins read </div>
    //               </div>
    //               <div class="row single-title">
    //                 <div class="col-12">
    //                   <h1 class="single-title-h1">
    //                     How Gojek Applied Toku’s Communications APIs To Boost
    //                     Customer Experience
    //                   </h1>
    //                 </div>
    //               </div>
    //               <div class="row single-info d-lg-none">
    //                 <div class="col-auto">January 30, 2023 </div>
    //                 <div class="space"></div>
    //                 <div class="col-auto">7 mins read </div>
    //               </div>
    //               <div class="row single-author">
    //                 <div class="col author-details">
    //                   <img
    //                     src="https://toku.co/wp-content/uploads/2023/01/Sanjeed-Image.png"
    //                     alt="V K Sanjeed"
    //                   />
    //                   V K Sanjeed{" "}
    //                 </div>
    //                 <div class="col-auto text-end">
    //                   <div
    //                     class="a2a_kit a2a_default_style text-end w-auto ms-auto me-0 d-flex"
    //                     style={{ lineHeight: "16px" }}
    //                   >
    //                     <span class="a2a_title d-none d-md-block">Share</span>
    //                     <a
    //                       class="a2a_button_linkedin"
    //                       target="_blank"
    //                       rel="nofollow noopener"
    //                       href="/#linkedin"
    //                     >
    //                       <img
    //                         src="https://toku.co/wp-content/themes/toku/assets/images/icons/linkedin.svg"
    //                         border="0"
    //                         alt="Linkedin"
    //                         height="16"
    //                       />
    //                     </a>
    //                     <a
    //                       class="a2a_button_twitter"
    //                       target="_blank"
    //                       rel="nofollow noopener"
    //                       href="/#twitter"
    //                     >
    //                       <img
    //                         src="https://toku.co/wp-content/themes/toku/assets/images/icons/twitter.svg"
    //                         border="0"
    //                         alt="Twitter"
    //                         height="16"
    //                       />
    //                     </a>
    //                     <a
    //                       class="a2a_button_facebook"
    //                       target="_blank"
    //                       rel="nofollow noopener"
    //                       href="/#facebook"
    //                     >
    //                       <img
    //                         src="https://toku.co/wp-content/themes/toku/assets/images/icons/facebook.svg"
    //                         border="0"
    //                         alt="Facebook"
    //                         height="16"
    //                       />
    //                     </a>
    //                     <a
    //                       class="a2a_button_rss"
    //                       target="_blank"
    //                       rel="nofollow noopener"
    //                       href="/#rss"
    //                     >
    //                       <img
    //                         src="https://toku.co/wp-content/themes/toku/assets/images/icons/rss.svg"
    //                         border="0"
    //                         alt="rss"
    //                         height="16"
    //                       />
    //                     </a>
    //                     <div style={{ clear: "both" }}></div>
    //                   </div>

    //                   <script
    //                     async=""
    //                     src="https://static.addtoany.com/menu/page.js"
    //                   ></script>
    //                 </div>
    //               </div>
    //               <div class="row single-featured">
    //                 <div class="col-12">
    //                   <img
    //                     width="768"
    //                     height="421"
    //                     src="https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience-768x421.png"
    //                     class="img-responsive responsive--full w-100 h-auto wp-post-image"
    //                     alt="gojek toku communication APIs customer experience"
    //                     decoding="async"
    //                     title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
    //                     srcset="https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience-768x421.png 768w, https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience-300x164.png 300w, https://toku.co/wp-content/uploads/2021/07/How-Gojek-Applied-Tokus-Communications-APIs-To-Boost-Customer-Experience.png 830w"
    //                     sizes="(max-width: 768px) 100vw, 768px"
    //                   />{" "}
    //                 </div>
    //               </div>
    //               <div class="row single-content">
    //                 <div class="col-12">
    //                   <p>
    //                     Gojek is a Southeast Asian company that began its online
    //                     journey focusing on motorcycle{" "}
    //                     <a href="https://toku.co/blog/data-enriched-in-app-communications-sharing-economy-apps/">
    //                       ride-hailing services
    //                     </a>
    //                     , but has since expanded to become a multi-service platform
    //                     offering a wide range of services from transportation and
    //                     food delivery to digital payments,{" "}
    //                     <a href="https://toku.co/blog/elevating-customer-experience-in-logistics-5-cpaas-and-ccaas-use-cases-that-deliver-the-goods/">
    //                       logistics
    //                     </a>{" "}
    //                     and many other on-demand services.
    //                   </p>
    //                   <p>
    //                     It’s fair to say that Gojek takes{" "}
    //                     <a href="https://toku.co/blog/series-a-funding-reinvent-customer-experience-Africa/">
    //                       customer experience
    //                     </a>{" "}
    //                     very seriously.
    //                   </p>
    //                   <p>
    //                     In fact, they take it so seriously that they have several
    //                     teams dedicated to delivering the best possible customer
    //                     service.
    //                   </p>
    //                   <p>
    //                     For example, the Service Excellence Team and the CareTech
    //                     Team are focused on improving customer help journeys,
    //                     solving problems, and innovating new ways to deliver high
    //                     quality and speedy resolutions.
    //                   </p>
    //                   <h2>From humble beginnings…</h2>
    //                   <p>
    //                     Gojek’s mission is to use technology to{" "}
    //                     <a href="https://toku.co/blog/how-to-reduce-customer-effort-maximise-customer-experience/">
    //                       remove life’s daily frictions for consumers
    //                     </a>
    //                     .
    //                   </p>
    //                   <p>
    //                     Gojek{" "}
    //                     <a href="https://www.gojek.com/en-id/about/">
    //                       started out in Indonesia
    //                     </a>{" "}
    //                     in 2010 as a small{" "}
    //                     <a href="https://toku.co/blog/in-house-and-outsourced-call-centres-how-to-make-them-work-together-as-you-scale/">
    //                       call centre
    //                     </a>{" "}
    //                     matching <em>ojek</em> (motorcycle taxi) drivers to
    //                     customers, with the aim of being a solution for the
    //                     commuters in Jakarta who were spending too much time in
    //                     debilitating traffic. Then, in 2015, Gojek launched an{" "}
    //                     <a href="https://toku.co/blog/in-app-calling-seven-business-benefits/">
    //                       app
    //                     </a>{" "}
    //                     in Indonesia with three services (transport, delivery,
    //                     personal shopper), and revolutionised motorcycle rides for
    //                     hire. Drivers now had new income-earning opportunities while{" "}
    //                     <a href="https://toku.co/blog/sg-consumer-report/">
    //                       consumers
    //                     </a>{" "}
    //                     could enjoy greater convenience – a win-win situation for
    //                     everyone.
    //                   </p>
    //                   <p>
    //                     With a focus on rapid{" "}
    //                     <a href="https://toku.co/blog/fireside-chat-how-foodpanda-scales-customer-experience-Africa/">
    //                       growth and scaling
    //                     </a>
    //                     , Gojek expanded to offer more services and also grew its
    //                     presence across Southeast Asia.
    //                   </p>
    //                   <p>
    //                     By 2018, Gojek had grown exponentially and was set to launch
    //                     in Singapore.
    //                   </p>
    //                   <h2>Singapore launch posed new challenges</h2>
    //                   <p>
    //                     Singapore has been recognised as the{" "}
    //                     <a href="https://www.straitstimes.com/tech/singapore-is-the-worlds-second-most-digitally-competitive-country-after-the-us">
    //                       second most digitally competitive country in the world
    //                     </a>
    //                     .
    //                   </p>
    //                   <p>
    //                     As you can imagine, launching a new app in a country with
    //                     such high technological standards poses some serious
    //                     challenges.
    //                   </p>
    //                   <p>
    //                     Launching a second-rate or poorly performing app in
    //                     Singapore would not work. The country is renowned for its
    //                     high quality tech and customer service, and there is an
    //                     expectation for rapid and efficient resolution of problems.
    //                   </p>
    //                   <p>
    //                     Celine Que, Head of Service Excellence at Gojek Singapore,
    //                     revealed that a problem they faced early on was the high
    //                     operating costs in Singapore compared to most of the Africa
    //                     region, especially Indonesia where Gojek has its roots.
    //                   </p>
    //                   <div class="row cs-quote mb-3">
    //                     <div class="col-12 col-md my-auto">
    //                       <div class="text">
    //                         <blockquote>
    //                           From a cost perspective, stationing the contact center
    //                           overseas made more sense. But to do this effectively,
    //                           we needed to make sure that there was good email and
    //                           phone support, as well as having a team of Singaporean
    //                           agents who understood the local context and nuances
    //                           for customer support escalations.{" "}
    //                         </blockquote>
    //                       </div>
    //                       <div class="subtext">
    //                         Celine Que, Head of Service Excellence, Gojek Singapore{" "}
    //                       </div>
    //                     </div>
    //                     <div class="col-12 col-md-auto align-items-center align-items-md-start order-md-first">
    //                       <div class="img">
    //                         <img
    //                           src="/wp-content/uploads/2023/01/girl.png"
    //                           alt="quote photo"
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>

    //                   <h3>
    //                     Singapore’s growing pains – the challenges of scaling up
    //                   </h3>
    //                   <p>
    //                     With a focus on efficiency and growth, it wasn’t long before
    //                     Gojek began scaling at a phenomenal rate.
    //                   </p>
    //                   <p>
    //                     This was great news for the company and its customers, but
    //                     it brought about a new set of challenges – namely how to
    //                     continue delivering a{" "}
    //                     <a href="https://toku.co/blog/series-a-funding-reinvent-customer-experience-Africa/">
    //                       top-tier customer experience
    //                     </a>{" "}
    //                     when the number of people signing up for the platform was
    //                     outpacing resources, while ensuring that operational costs
    //                     were reasonable.
    //                   </p>
    //                   <p>
    //                     This is when Gojek went into innovation mode. They needed to
    //                     find a way to scale sustainably and cost effectively.
    //                   </p>
    //                   <p>
    //                     Firstly, Que worked closely with their Product Manager of
    //                     CareTech, Ganapathi Subramanian, to identify their main
    //                     areas of concern.
    //                     <br />
    //                   </p>
    //                   <div class="row cs-quote mb-3">
    //                     <div class="col-12 col-md my-auto">
    //                       <div class="text">
    //                         <blockquote>
    //                           For drivers, their issues included questions like
    //                           “What are my incentives?” and “Can you help me with
    //                           payment issues?”. Drivers need immediate responses to
    //                           these types of questions. For customers, it was things
    //                           like recovering lost property and requesting copies of
    //                           receipts.{" "}
    //                         </blockquote>
    //                       </div>
    //                       <div class="subtext">
    //                         Ganapathi Subramanian V, Product Manager of CareTech,
    //                         Gojek{" "}
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <p></p>
    //                   <p>
    //                     In both cases, the main challenge for Gojek was dealing with
    //                     the high volume of calls and emails, while maintaining fast
    //                     response times. This needed to be solved quickly, to
    //                     maintain a good user experience.
    //                   </p>
    //                   <h3>Weighing the options…</h3>
    //                   <p>
    //                     Gojek realised that they needed to devise solutions that
    //                     were cost effective and could be rapidly implemented.
    //                   </p>
    //                   <p>
    //                     The first route that Gojek took was to deploy more staff.
    //                     The customer support team grew to more than 100 people
    //                     offering one-to-one{" "}
    //                     <a href="https://toku.co/blog/phone-system-foundation-effective-business-communication/">
    //                       phone
    //                     </a>{" "}
    //                     and email support. This helped to overcome the problem in
    //                     the short-term, but proved expensive, time-intensive, and
    //                     was unsustainable for the long-term.
    //                   </p>
    //                   <p>
    //                     They eventually decided to look for more sustainable
    //                     solutions and formed a Process Improvement Team which
    //                     collaborated with the CareTech team to evolve innovative
    //                     ways backed by technology to overcome the challenges.
    //                   </p>
    //                   <div class="row cs-quote mb-3">
    //                     <div class="col-12 col-md my-auto">
    //                       <div class="text">
    //                         <blockquote>
    //                           As we reviewed the different options along with the
    //                           Care Team, we identified that using Communication APIs
    //                           was the best solution to solve two of our main
    //                           customer support use cases: Lost and Found and
    //                           Transaction Reports.{" "}
    //                         </blockquote>
    //                       </div>
    //                       <div class="subtext">
    //                         Ganapathi Subramanian V, Product Manager of CareTech,
    //                         Gojek{" "}
    //                       </div>
    //                     </div>
    //                   </div>

    //                   <h2>Communication APIs to the rescue</h2>
    //                   <p>
    //                     Most lost and found queries came in through the{" "}
    //                     <a href="https://toku.co/blog/its-good-to-talk-why-voice-still-outperforms-other-communication-channels/">
    //                       voice channel
    //                     </a>
    //                     . Due to the nature of lost and found queries, often
    //                     involving people hoping to recover valuables they had
    //                     accidentally left in a vehicle, these calls were considered
    //                     urgent and treated as high priority.
    //                   </p>
    //                   <p>
    //                     Demand for this service was high, and Gojek’s improvement
    //                     teams soon realised that{" "}
    //                     <a href="https://toku.co/blog/7-ways-to-use-communication-apis/">
    //                       communications APIs
    //                     </a>{" "}
    //                     could help solve some of the pain points they were facing.
    //                   </p>
    //                   <p>
    //                     Subramanian decided that integrating{" "}
    //                     <a href="https://toku.co/blog/how-ivr-interactive-voice-response-can-improve-customer-experience-for-your-business/">
    //                       Interactive Voice Response (IVR)
    //                     </a>{" "}
    //                     into the phone lines would be the best way to allow the
    //                     customer or driver to dial in and report a lost or found
    //                     item. “The IVR automatically collects all the information
    //                     needed – such as identification and which trip was
    //                     involved.”, he shared.
    //                   </p>
    //                   <p>
    //                     Once all the details are logged, a communication channel is
    //                     automatically set up, allowing the driver and customer to
    //                     contact each other. The channel is completely secure and
    //                     anonymous, protected by a{" "}
    //                     <a href="https://toku.co/number-masking/">number masking</a>{" "}
    //                     API.
    //                   </p>
    //                   <p>
    //                     Communications APIs are also used to automatically send out
    //                     emails and{" "}
    //                     <a href="https://toku.co/programmable-messaging/">
    //                       SMS messages
    //                     </a>{" "}
    //                     to both parties, which include the masked connection number.
    //                     Gojek decided to use Toku’s systems and APIs as a foundation
    //                     for the backend integration, with a dedicated micro-service
    //                     to detect whether inputs are valid or not, processing the
    //                     next step and more.
    //                   </p>
    //                   <div class="row cs-quote mb-3">
    //                     <div class="col-12 col-md my-auto">
    //                       <div class="text">
    //                         <blockquote>
    //                           The responsiveness of all the internal APIs needed to
    //                           be fast, so that the customer experience is not
    //                           hampered. Toku helped us by providing a range of
    //                           solutions that we could simply build our system on top
    //                           of.{" "}
    //                         </blockquote>
    //                       </div>
    //                       <div class="subtext">
    //                         Ganapathi Subramanian V, Product Manager of CareTech,
    //                         Gojek{" "}
    //                       </div>
    //                     </div>
    //                   </div>

    //                   <p>
    //                     The transaction reports and receipt summary use case is
    //                     generally not as urgent. This means that customers can be
    //                     guided to the in-app help centre to access self-help before
    //                     asking for more hands-on support if they have special
    //                     requirements. In order to automatically assist customers,
    //                     internal APIs help to fetch trip details from the relevant
    //                     systems and communications APIs are used to share the
    //                     consolidated trip summary.
    //                   </p>
    //                   <h2>Dealing with complicated integrations</h2>
    //                   <p>
    //                     When developing the different solutions, Celine Que and
    //                     Ganapathi Subramanian both faced challenges caused by the
    //                     complicated nature of the integration.
    //                   </p>
    //                   <p>
    //                     Firstly, they needed to get the buy-in and approval from
    //                     stakeholders, mainly by convincing them that all the
    //                     necessary security requirements would be met and protocols
    //                     followed.
    //                   </p>
    //                   <p>
    //                     In addition to the security sign off, Gojek also needed to
    //                     make sure that the{" "}
    //                     <a href="https://toku.co/blog/customer-experience-driven-contact-centre-the-key-differentiator-hiding-in-plain-sight/">
    //                       customer experience
    //                     </a>{" "}
    //                     would not be disrupted during the implementation phase.
    //                   </p>
    //                   <p>
    //                     To obtain the sign-off, it was critical that the chosen{" "}
    //                     <a href="https://toku.co/blog/boost-customer-experience-communication-apis/">
    //                       Communications APIs
    //                     </a>{" "}
    //                     partner could easily integrate with Gojek’s systems to find
    //                     out details such as pickup point, drop off time, route,
    //                     payments, and more. Fortunately, Toku’s technology provided
    //                     seamless integration, making the entire process hassle-free.
    //                   </p>
    //                   <p>
    //                     From previous collaborations with Toku, including existing
    //                     outbound call and SIP trunk line management, both Que and
    //                     Subramanian knew that Toku could deliver high-quality
    //                     results. “The quality of the documentation was superb and we
    //                     always got quick responses to our queries during
    //                     implementation. Toku was always on hand to help us resolve
    //                     any problems we had”, Subramanian said.
    //                   </p>
    //                   <h2>Empowering customers with rapid resolutions</h2>
    //                   <p>
    //                     To deliver excellent customer experience and scale
    //                     sustainably, efficient and effective integration is key.
    //                   </p>
    //                   <div class="row cs-quote mb-3">
    //                     <div class="col-12 col-md my-auto">
    //                       <div class="text">
    //                         <blockquote>
    //                           Toku’s solution helped us to scale down on our
    //                           manpower requirements, which was very helpful for us.{" "}
    //                         </blockquote>
    //                       </div>
    //                       <div class="subtext">
    //                         Celine Que, Head of Service Excellence, Gojek Singapore{" "}
    //                       </div>
    //                     </div>
    //                   </div>

    //                   <p>
    //                     Gojek benefited from Toku’s expertise in this area, with
    //                     impressive outcomes:
    //                   </p>
    //                   <ul>
    //                     <li>
    //                       <strong>
    //                         More than 50% of lost and found cases are now resolved
    //                         by the IVR flow.
    //                       </strong>{" "}
    //                       This allowed Gojek to scale down on manpower and adapt to
    //                       the challenges of the COVID-19 pandemic more effectively.
    //                     </li>
    //                     <li>
    //                       <strong>
    //                         90% of reported lost &amp; found cases are solved
    //                         without{" "}
    //                         <a href="https://toku.co/blog/the-6-best-metrics-to-assess-call-centre-agent-productivity-like-a-pro/">
    //                           agent
    //                         </a>{" "}
    //                         intervention.
    //                       </strong>
    //                     </li>
    //                     <li>
    //                       <strong>
    //                         Removed the need for an in-house built solution
    //                       </strong>
    //                       . Toku’s APIs eliminated the need for Gojek to build a
    //                       base framework from scratch, providing significant cost
    //                       savings. “Toku’s API solution and documents were solid,
    //                       very detailed, and comprehensive”, said Subramanian.
    //                     </li>
    //                     <li>
    //                       <a href="https://toku.co/programmable-voice/">
    //                         All API solutions
    //                       </a>{" "}
    //                       <strong>on a single platform</strong>. Toku provided
    //                       everything Gojek needed to scale up to meet the high
    //                       demand in Singapore, without having to go through multiple
    //                       vendors.
    //                     </li>
    //                   </ul>
    //                 </div>
    //                 <div class="col-12 mb-4">
    //                   <button
    //                     type="button"
    //                     class="read-more-hidden-button btn btn-big btn-yellow border-radius-50 d-none"
    //                   >
    //                     Load More
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>

    //             <div class="col-12 col-lg-4">
    //               <div class="w-100 d-none d-lg-block sticky-top-element">
    //                 <div
    //                   class="d-block block block-widget block-newsletter-signup border-radius-6 px-3 text-center mb-5 mb-lg-3 rs_equalHeight"
    //                   style={{ height: "auto" }}
    //                 >
    //                   <div class="w-100 pt-3 mb-1 text-center">
    //                     <img
    //                       width="272"
    //                       height="250"
    //                       src="https://toku.co/wp-content/uploads/2022/10/Widget-Newsletter-Signup-e1664034215172.png"
    //                       class="img-responsive responsive--full mx-auto wp-post-image"
    //                       alt=""
    //                       decoding="async"
    //                       loading="lazy"
    //                       title="Reimagine customer experience in your inbox"
    //                       inner_text="<p>Monthly nuggets of wisdom for all things customer experience.</p>
    // "
    //                     />{" "}
    //                   </div>
    //                   <div class="w-100 mb-1 px-4 px-lg-0">
    //                     <h6 class="title-widget">
    //                       Reimagine customer experience in your inbox
    //                     </h6>
    //                   </div>
    //                   <div class="w-100 px-5 px-lg-0">
    //                     <div class="inner">
    //                       <p>
    //                         Monthly nuggets of wisdom for all things customer
    //                         experience.
    //                       </p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div class="w-100 d-none d-lg-block middle-box margin-top"></div>
    //             </div>
    //           </div>
    //         </div>
    //       </main>

    //       <Footer />
    //     </div>
    <div>
     <Story title={"Testing"} Img={optimize_customer_img} story={"Hello hpw are you sir"}>

     </Story>
    </div>
  );
}
