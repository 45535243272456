import "./debtcollection.css";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import MAIN from "../../assets/images/Debt-01.png";
import img2 from "../../assets/images/Debt-2.png";
import img3 from "../../assets/images/Debt-3 .png";
import img4 from "../../assets/images/Debt-4.png";
import img5 from "../../assets/images/Debt-5.png";
import img6 from "../../assets/images/Debt-6.png";
import FICON from "../../assets/images/Group 1178.svg";
import SICON from "../../assets/images/Group 1177.svg";
import TICON from "../../assets/images/Group 1176.svg";
import SIMPL from "../../assets/images/SIMPIFY.png";
import EFFORT from "../../assets/images/EFFORT.png";

import icon1 from "../../assets/images/Debt-Analytics.svg";
import icon2 from "../../assets/images/DebtStreamline Operations.svg";
import icon3 from "../../assets/images/Debt-Compliance.svg";
import icon4 from "../../assets/images/Debt-SMS - Email.svg";
import icon5 from "../../assets/images/Debt-Number Masking.svg";
import icon6 from "../../assets/images/Debt-Autodialer.svg";
export default function DebtCollection(props) {
  return (
    <div div className="debtCollection">
      <Navbar
        heading="Streamline Debt Recovery: Fast, Efficient, and Proactive"
        text="With our innovative approach, we combine the power of technology and human interaction to maximize results."
        img={MAIN}
        extra_heading="Debt Collection"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>
      <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
        <div class="container">
          <div class="row ">
            <div class="col-12 mx-auto col-md-8">
              <h2 class="style-h2">
                Accelerate Debt Recovery: Personalized Communication, Maximum
                Results
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "430px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={icon6}
                      alt="Group 1000005055"
                      style={{ height: "60px" }}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Autodialer </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Designed specifically for debt collection, our autodialer
                      system automates the dialing process, allowing your team
                      to reach more debtors in less time.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any  p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "430px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={icon5}
                      alt="Group 1000005056"
                      style={{ height: "60px" }}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Number Masking</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      A powerful tool that safeguards the privacy of both
                      debtors and creditors while facilitating effective
                      communication, fostering trust and openness between both
                      parties.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any  p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "430px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={icon4}
                      alt="Group 1000005168"
                      style={{ height: "60px" }}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">SMS / Email Service</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Benefit from faster and more efficient communication,
                      improved debtor response rates, and increased debt
                      recovery.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Create a platform with user preference in mind
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Design your platform with the user in mind As consumer
                  preferences evolve swiftly, users now expect an array of
                  channels, enhanced communication options, and exceptional
                  customer service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters pb-3"
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered pb-3">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers considers SMS as a desired communication channel,
                surpassing all others in terms of popularity and preference
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">57</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers express a preference for receiving reminders via
                SMS, highlighting its effectiveness as a communication medium*
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers favor app environments regarding all kinds of
                workplace communications as their preferred communication
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Engage with clients on their preferred terms of communication
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#f1f5fe" }}
        class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center"
      >
        <div class="container">
          <div class="row ">
            <div class="col-12 mx-auto col-md-8">
              <p class="p-small">
                Finances are a delicate matter. If clients feel they are not
                receiving top-notch service, they may turn to your competitors.
                Stay proactive in understanding their communication expectations
                to ensure their satisfaction remains high.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers, surpassing all other options, would like to choose
                SMS as a communication channel.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">54</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers would be interested in voice calls if they want to
                instantly resolve issues.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">45</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers would like to be updated over the emails for policy
                updates.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    brands?
                  </h4>
                  <div class="description mb-3">
                    *Read KASOOKOO African Consumer Engagement Report
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="">
                    Download Here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img src={icon1} alt=" IN-APP VOICE" />
                    Analytics
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">Real-time Data & Reports</div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      By leveraging the power of data visualization, you can
                      quickly identify areas of success and areas that require
                      attention. This empowers you to allocate resources
                      effectively, refine your communication strategies, and
                      focus on tactics that yield the best results.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={img2}
                  alt="Frame 4625632"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img src={icon2} alt="Kasookoo CONTACT CENTRE" />
                    Streamline Operations 
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                  Enhance Operational Efficiencies
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                    Through advanced automation driven by intelligent algorithms, we consistently refine and fine-tune our processes to ensure an optimal and tailored experience for every customer.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={img3}
                  alt="Frame 4625643"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pt-0"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center ">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img src={icon3} alt="Kasookoo EMBEDDABLES " />
                    Ensuring Compliance 
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                  Your Secure Path to Success
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                    Our comprehensive approach to risk reduction and compliance management ensures that your debt collection activities adhere to regulatory standards and minimize potential liabilities.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={img4}
                  alt="Frame 465"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-10"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463399.svg"
                      alt="TOKU CAMPAIGN MANAGER "
                    />
                    KASOOKOO CAMPAIGN MANAGER
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Streamline your marketing efforts
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Achieve widespread user outreach effortlessly by
                      consolidating all your messaging campaigns into a unified
                      platform, enabling you to seamlessly send mass updates,
                      offers, and information.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/campaign-manager"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More About Sending Bulk Messages
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46259-4.png"
                  alt="Frame 46259"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463407.svg"
                      alt="TOKU NUMBER MASKING"
                    />
                    KASOOKOO NUMBER MASKING
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Protect your customers' personal information
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Navigating online marketplaces can be daunting. Safeguard
                      the privacy of your customers and users by keeping their
                      phone numbers confidential, enhancing the security of
                      calls and messages, discouraging off-platform
                      transactions, and facilitating seamless communication
                      within the platform.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/number-masking"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Start Protecting Your Customers’ Data!
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Number-masking-image-12.png"
                  alt="Number masking image 12"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one  order-md-last ">
                <img
                  src="https://toku.co/wp-content/uploads/2022/12/Frame-46290-1.png"
                  alt="Want to improve operational efficiency in Africa?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-first ">
                <img
                  src="https://toku.co/wp-content/uploads/2022/12/Frame-46289.png"
                  alt="Want to improve operational efficiency in Africa?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Looking to enhance operational efficiency in Africa?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    class="blact_btn mb-5 mb-md-0 mx-auto a-btn"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer></Footer>
    </div>
  );
}
